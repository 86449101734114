
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_935IvdWOuLBXMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as indexFCBF9LScepMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guidesH1kdA8VrCqMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_93YDw4jMLgvOMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as indexfNg02IwoiDMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbackYFCGdrkFA8Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45usD9EZfCwuFTMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callbackc2zFYSmS4XMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergeGWUHJn0f3AMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as buyer_45protection_45claimWSZmCsK2BEMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as terms_45and_45conditionsLAg9GhpryRMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as comprehensiveDQewSV2fMxMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as indexbzaY3OoGbDMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_933QG7pDJooQMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as detailsVtyd0xiAtQMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsdKM5bgExXdMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as _91slug_93kpm312LvWdMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexA5qgRQaGnxMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexTSa8v1dJTBMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticeanuaCUnrXkMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintsZPJwTwrey9Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usWAYqxnEbRxMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_931mCcEBb591Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexb3RPXncIA0Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_93Iz4FyD1cDFMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93PPi20dqgCFMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settingsr6T0CUupcvMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexmMZbXnNUVFMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as claim_45offerEm4AtapSfzMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as indexbvnJPAdJJqMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as indexCkM9M0O2XLMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexAMFFmenC5MMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as index3HQz3j20slMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as additional_45detailsAVTu9Ik59MMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as completionffDwOssxl8Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as detailsV2YKe5YaVzMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as _91step_93JSzAYzKT3pMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93ESIeIfCqqrMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93g4K2kCZLymMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as _91step_935Y06t9bThXMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as _91step_93tXhBLDfmgrMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as lic_45details5F9xyj5LTqMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as rebatepCry7J3ApZMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as digital_45eicvYd8mTdb9aMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as errorxCe4dsTSBeMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as thank_45youxr6h6Xb44BMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as coffs_45harbour9MY6pwUDAYMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as indexjJTPftJJF1Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93G8uFCKxYIxMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsuuO0bOaptEMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45yougDJBtNJkg1Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93IToX5IHom7Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexopyeEpAW67Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_93kUYRRq3mDTMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as indexbFtu8CPRGnMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45passwordjgT1xC7mXIMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as set_45passwordcPUR6BNTYqMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backKuNaXbweYnMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as _91step_932U6th3oOy1Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expiredPWlbUKKcNEMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexX01f9qs3QnMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as index7OEPP09C2VMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as error9Vev9iEaRMMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexjyuGsldue7Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45bQXdyz4ud3yMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chatMavCsfYEKqMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indexte5aHRJLaPMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addNRpTMr7nz6Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupiEEGdzSL0AMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45saleHSYDnnqsvOMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as index7eBeZIQG1oMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93uUtHwlFgcMMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as detailsSW0kmrGJKKMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step07nKISw3DNCMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as detailsEVYclISHffMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as _91step_935LV2p1HxOtMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as step07iCL0vqVJkMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexA032TCioTcMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45youE8Ueyf51scMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as indexVFJfgFdbzDMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexxAPRtvfCdBMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as indexziY21kdPvcMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexiipekIDcHoMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93V5CqpXTL60Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93MHz4KG3G5ZMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmationwMl0Zf3pN5Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailsOxCtNUr0P8Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexUoKc440Sb0Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsmvWVXmPLVzMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youEtB4XUhfkCMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_931DYMnwKGsLMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as indexY1rQSgomzQMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexoBUndFCzo9Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45rates84LdrpHqCpMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93rRS6Yyg9RKMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsq36czlaju5Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45you717Q474okAMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as indexi6w0mXPLopMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/index.vue?macro=true";
import { default as existing_45customersthJlPGMunsMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as confirmationyXHXigbUxUMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailshkqEDQbx3TMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexKnSB2VpMttMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexfubQkvRIawMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as addG9YzRxcSlfMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youFVRMV7vupMMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as indexpKVP0QU4vqMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as successnIMtKj1taLMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_93lzyEPDdwdLMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailsKqnM6X3goOMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as step0gEItnGy2uSMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as loginEe1nwipKM0Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_934sCzR3KIsxMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_93anKc5uV5OpMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexsR6udY1qylMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipsGW1Q7Xa1NuMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as indexIR0xrN5CVdMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91step_93dXxhCk9tFWMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as details6PhpkOD5qwMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as thank_45youM7sSMwkdOmMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as post_45sale_45thank_45youWHogxPpsbqMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_93L8d5ahRzuIMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_939N0Hd7P6STMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93JFWVDnIz5rMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policyuTPW8NXldvMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registerXJsF8u3ECeMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordWcvV4Nj9XgMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as sitemaphSKMWM3LX8Meta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as terms_45of_45useComWTyjzAUMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifyo5Rx7t9EDlMeta } from "/codebuild/output/src550119244/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbackYFCGdrkFA8Meta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_931mCcEBb591Meta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsr6T0CUupcvMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexmMZbXnNUVFMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerEm4AtapSfzMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexbvnJPAdJJqMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexCkM9M0O2XLMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexAMFFmenC5MMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: index3HQz3j20slMeta?.props ?? {"verificationNeeded":true},
    meta: index3HQz3j20slMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product(.*)*/:vertical()/additional-details",
    meta: additional_45detailsAVTu9Ik59MMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product(.*)*/:vertical()/completion",
    meta: completionffDwOssxl8Meta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product(.*)*/details",
    meta: detailsV2YKe5YaVzMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product(.*)*/form/energy/step/:step()",
    meta: _91step_93JSzAYzKT3pMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product(.*)*/form/generic/step/:step()",
    meta: _91step_93ESIeIfCqqrMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product(.*)*/form/health-insurance/step/:step()",
    meta: _91step_93g4K2kCZLymMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product(.*)*/form/home-loan/step/:step()",
    meta: _91step_935Y06t9bThXMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product(.*)*/form/life-insurance/step/:step()",
    meta: _91step_93tXhBLDfmgrMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product(.*)*/lic-details",
    meta: lic_45details5F9xyj5LTqMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product(.*)*/rebate",
    meta: rebatepCry7J3ApZMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordjgT1xC7mXIMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordcPUR6BNTYqMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_935LV2p1HxOtMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginEe1nwipKM0Meta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerXJsF8u3ECeMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordWcvV4Nj9XgMeta || {},
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src550119244/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]